@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:wght@300;400;500;600;700&display=swap');


:root {
  --color: #1B1E26;
  --backgroundColor: #ffffff;
  --grey: #5d5d5d;
  --white: #ffffff;
  --lightOrange: #18E2D3;
  --orange: #ff8000;
  --font: 'Quicksand', sans-serif;
  --academicLavendar: #51538E;
  --lightAcademicLavendar: #a5a7cc;
}

:fullscreen,
::backdrop {
  background-color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 14px;
}

@media (max-width: 2000px) {

  html {
    font-size: 21px;
  }
}


@media (max-width: 1900px) {

  html {
    font-size: 20px;
  }
}

@media (max-width: 1800px) {

  html {
    font-size: 19px;
  }
}

@media (max-width: 1650px) {

  html {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {

  html {
    font-size: 17px;
  }
}

@media (max-width: 1550px) {

  html {
    font-size: 16px;
  }
}


@media (max-width: 1475px) {

  html {
    font-size: 15px;
  }
}


@media (max-width: 1300px) {

  html {
    font-size: 14px;
  }
}

@media (max-width: 1220px) {

  html {
    font-size: 13px;
  }
}


@media (max-width: 1150) {

  html {
    font-size: 12px;
  }
}


@media (max-width: 1140px) {

  html {
    font-size: 11px;
  }
}

@media (max-width: 990px) {

  html {
    font-size: 10px;
  }
}

@media (max-width: 820px) {

  html {
    font-size: 9px;
  }
}

@media (max-width: 720px) {

  html {
    font-size: 8px;
  }
}

@media (max-width: 675px) {

  html {
    font-size: 7px;
  }
}



